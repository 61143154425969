import React from 'react';
import styles from './Main.module.sass';
import ShapeA from '../../assets/oblong_a.svg';
import ShapeB from '../../assets/compact_fat_squiggle.svg';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import MobileSpace, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

export default class Main extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <Grid>
          <MobileSpace size={SpacerSize.large} />
          <img className={styles.ShapeA} src={ShapeA} alt="" />
          <img className={styles.ShapeB} src={ShapeB} alt="" />
          <div className={styles.TitleContainer}>
            <div className={styles.Item}>Get the</div>
            <div className={styles.Item}><span className={styles.Emphasized}>most</span> out</div>
            <div className={styles.Item}>of life.</div>
          </div>
        </Grid>
      </Section>
    )
  }

}