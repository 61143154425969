import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../reusable/button/Button';
import styles from './SectionB.module.sass'
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section'
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Bicycle from '../../assets/bicycle.svg';
import MobileSpacer from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

const title = "Our mission."
const paragraphs = [
  "Sometimes the smallest changes can make the biggest difference.",
]

export default class SectionB extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.dark}>
        <img className={styles.Bicycle} src={Bicycle} alt="" />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={false}
            title={title}
            paragraphs={paragraphs}
            style={TextGroupStyle.small}>
            <div className={styles.Button}>
              <Link to="/mission">
                <Button dark={false} title={"LEARN MORE"} />
              </Link>
            </div>
          </TextContainer>
        </Grid>
        <MobileSpacer />
      </Section>
      )
    }

  }
