import React from 'react';
import styles from './Section.module.sass';

enum SectionBackgroundStyle {
  light,
  mediumLight,
  mediumDark,
  dark,
  veryDark
}

type SectionProps = {
  style: SectionBackgroundStyle;
  onlySideMargins?: boolean;
};

export {
  SectionBackgroundStyle
}

export default class Section extends React.Component<SectionProps> {
  
  render() {
    let cssClass = styles.Section;
    switch (this.props.style) {
      case SectionBackgroundStyle.light:
        cssClass = styles.SectionLight;
        break;
      case SectionBackgroundStyle.mediumLight:
        cssClass = styles.SectionMediumLight;
        break;
      case SectionBackgroundStyle.mediumDark:
        cssClass = styles.SectionMediumDark;
        break;
      case SectionBackgroundStyle.dark:
        cssClass = styles.SectionDark;
        break;
      case SectionBackgroundStyle.veryDark:
        cssClass = styles.SectionVeryDark;
        break
      default:
        break
    }
    return (
      <div className={cssClass}>
        <div className={this.props.onlySideMargins === true ? styles.ContentOnlySideMargins : styles.Content}>
          {this.props.children}
        </div>
      </div>
    )
  }

}