import React from 'react';
import styles from './TextGroupSectionC.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Christie from '../../assets/christie.png';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

const title = "Christie Greer."
const subtitle = "Lead Pediatric Occupational Therapist & Director of OT at Park Century School"
const paragraphs = [
  "Christie joined Jennifer and the MOST team as a pediatric Occupational Therapist in August 2018 after receiving her Doctorate from The University of Southern California. As a devoted Trojan, she also received her Bachelor of Arts in Psychology/ French and Master of Arts in Occupational Therapy from USC. She completed her doctoral residency at a non-profit school for students with special needs, where she focused the curriculum on strengthening functional skills such as self-care, social skills, and other fine motor tasks.",
  "Christie has her certification in Sensory Integration as well as special training Handwriting without Tears and Executive Functioning. When Christie is not spending time with dogs and working with children, she enjoys going to the beach with family and friends, as well as teaching weekly Bar Method classes.",
];

export default class TextGroupSectionC extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.dark}>
        <img className={styles.Christie} src={Christie} alt="" />
        <MobileSpacer size={SpacerSize.medium} />
        <Grid>
          <TextContainer 
            className={styles.TextContainer}
            dark={false} 
            title={title}
            subtitle={subtitle}
            paragraphs={paragraphs} 
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}