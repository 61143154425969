import React from 'react';
import styles from './TextGroupSectionA.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import GirlWriting from '../../assets/girl_writing.png';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Circle1 from '../../assets/circle_1.svg';
import Grid from '../../reusable/grid/Grid';

const title = "Tailored evaluation."
const text = "We believe every child is unique, and our services reflect this. We offer customized evaluations and screenings to fit your needs."

export default class TextGroupSectionA extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <img className={styles.GirlWriting} src={GirlWriting} alt="" />
        <MobileSpacer size={SpacerSize.small} />
        <MobileSpacer size={SpacerSize.medium} />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={true}
            image={Circle1}
            title={title}
            paragraphs={[text]}
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}
