import React from 'react';
import styles from "./NavLogo.module.sass";
import MostLogoLight from "../assets/logo_updated_cream.svg";
import MostLogoDark from "../assets/logo_updated_blue.svg";

type NavLogoProps = {
  light?: boolean;
};

export default class NavLogo extends React.Component<NavLogoProps> {

  render() {
    return (
      <div className={styles.NavLogo}>
        <img src={this.props.light === true ? MostLogoLight : MostLogoDark} alt="" />
      </div>
    );
  }

}
