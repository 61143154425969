import React from 'react';
import styles from './MobileSpacer.module.sass';

enum SpacerSize {
  small,
  medium,
  large
}

export {
  SpacerSize
}

type MobileSpacerProps = {
  size?: SpacerSize,
}

export default class MobileSpacer extends React.Component<MobileSpacerProps> {

  render() {
    let cssClass = styles.MobileSpacerMedium
    switch (this.props.size) {
      case SpacerSize.small:
        cssClass = styles.MobileSpacerSmall;
        break;
      case SpacerSize.medium:
        cssClass = styles.MobileSpacerMedium;
        break;
      case SpacerSize.large:
        cssClass = styles.MobileSpacerLarge;
        break
    }

    return (
      <div className={cssClass}>
        {this.props.children}
      </div>
    )
  }

}