import React from 'react';
import styles from './TextGroupSectionF.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Shannon from '../../assets/shannon_1.png';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

const title = "Shannon Harooni."
const subtitle = "Pediatric Occupational Therapist"
const paragraphs = [
  "Shannon joined the MOST team as a pediatric occupational therapist in April 2024, after a year of providing sensory-based therapy to children of varying diagnoses and ages. Shannon received her Masters degree in Occupational Therapy from the University of Southern California (USC) in 2022, and received her Bachelors of Arts in Communication Studies, from the University of California, Los Angeles (UCLA) in 2017. Shannon completed her certification in Sensory Integration Therapy from USC, and is passionate about helping children achieve maximum independence and confidence in their daily routines/activities. She believes that close collaboration with parents, teachers, health care providers, and all those involved in a child's development is vital to fostering an environment in which a child can develop and progress while simultaneously feeling a sense of security and support.",
  "Outside of OT, Shannon enjoys spending time with friends and family, being near the ocean, cooking, and traveling."
];

export default class TextGroupSectionF extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <img className={styles.Shannon} src={Shannon} alt="" />
        <MobileSpacer size={SpacerSize.medium} />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark
            title={title}
            subtitle={subtitle}
            paragraphs={paragraphs} 
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}