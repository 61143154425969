import React from 'react';
import styles from './TitleDescriptionGroup.module.sass';

enum TextGroupStyle {
  small,
  large
}

enum Alignment {
  left,
  center,
  right
}

type TitleDescriptionGroupProps = {
  className?: string;
  dark: boolean;
  image?: any;
  title?: string;
  subtitle?: string;
  paragraphs?: Array<string>;
  style?: TextGroupStyle;
  alignment?: Alignment;
};

export {
  TextGroupStyle,
  Alignment
}

export default class TitleDescriptionGroup extends React.Component<TitleDescriptionGroupProps> {

  render() {
    let headerCSSClass = this.props.dark ? styles.TitleTextDark : styles.TitleTextLight
    let textCSSClassName = this.props.dark ? styles.TextDark: styles.TextLight
    switch (this.props.style) {
    case TextGroupStyle.small:
      headerCSSClass = this.props.dark ? styles.SmallTitleTextDark : styles.SmallTitleTextLight
      textCSSClassName = this.props.dark ? styles.SmallTextDark : styles.SmallTextLight
      break
    case TextGroupStyle.large:
      headerCSSClass = this.props.dark ? styles.TitleTextDark : styles.TitleTextLight
      textCSSClassName = this.props.dark ? styles.TextDark: styles.TextLight
      break
    }
    const subtitleCSSClassName = this.props.dark ? styles.SubtitleTextDark: styles.SubtitleTextLight

    let alignmentClass = styles.Left
    switch (this.props.alignment) {
    case Alignment.left:
      alignmentClass = styles.Left
      break
    case Alignment.center:
      alignmentClass = styles.Center
      break
    case Alignment.right:
      alignmentClass = styles.Right
      break
    }

    return (
      <div className={`${this.props.className || ""} ${styles.Container} ${alignmentClass}`}>
        {this.props.image && <img className={styles.Image} src={this.props.image} alt="" />}
        <div className={headerCSSClass}>
          {this.props.title}
        </div> 
        {this.props.subtitle && 
          <div className={subtitleCSSClassName}>{this.props.subtitle}</div>
        }
        {this.props.paragraphs && this.props.paragraphs.map((paragraph, idx) => (
          <div>
            <div className={textCSSClassName}>{paragraph}</div>
            {(this.props.paragraphs && (this.props.paragraphs.length - 1) !== idx)  && <br />}
          </div>
        ))}
        {this.props.children}
      </div>
    )
  }

}