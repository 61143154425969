import React from 'react';
import NavBar from '../nav_bar/NavBar';
import Main from './main/Main';
import Description from './description/Description';
import styles from './Mission.module.sass';
import TextGroupSectionA from './text_group_section_a/TextGroupSectionA';
import TextGroupSectionB from './text_group_section_b/TextGroupSectionB';
import TextGroupSectionC from './text_group_section_c/TextGroupSectionC';
import GetInTouchFooter from '../get_in_touch_footer/GetInTouchFooter';
import Footer from '../footer/Footer';
import { SectionBackgroundStyle } from '../reusable/section/Section';

function Mission() {
  return (
    <div className={styles.Mission}>
      <NavBar style={SectionBackgroundStyle.dark} />
      <Main />
      <Description />
      <TextGroupSectionA />
      <TextGroupSectionB />
      <TextGroupSectionC />
      <GetInTouchFooter />
      <Footer />
    </div>
  );
}

export default Mission;