import React from 'react';
import { Link } from 'react-router-dom';
import NavLogo from "./NavLogo"
import HamburgerIconLight from "../assets/hamburger_icon_light.svg";
import HamburgerIconDark from "../assets/hamburger_icon_dark.svg";
import XIconLight from "../assets/x_icon_light.svg";
import XIconDark from "../assets/x_icon_dark.svg";
import styles from "./NavBar.module.sass"
import Section, { SectionBackgroundStyle } from '../reusable/section/Section';

const missionNavItemHeaderText: String = "MISSION"
const teamNavItemHeaderText: String = "TEAM"
const methodNavItemHeaderText: String = "METHOD"
const contactNavItemHeaderText: String = "CONTACT"

type NavBarState = {
  expanded: boolean
}

type NavBarProps = {
  style: SectionBackgroundStyle
}

export default class NavBar extends React.Component<NavBarProps, NavBarState> {

  constructor(props: NavBarProps) {
    super(props);
    this.state = { expanded: false, };
  } 
  
  render() {
    let itemCSSClass = styles.NavBarItemLight;
    let lineCSSClass = styles.NavBarLineDark;
    let logoLight = false;
    switch (this.props.style) {
    case SectionBackgroundStyle.light:
      itemCSSClass = styles.NavBarItemDark;
      lineCSSClass = styles.NavBarLineDark;
      logoLight = false;
      break;
    case SectionBackgroundStyle.mediumLight:
      itemCSSClass = styles.NavBarItemDark
      lineCSSClass = styles.NavBarLineDark;
      logoLight = false;
      break;
    case SectionBackgroundStyle.mediumDark:
      itemCSSClass = styles.NavBarItemDark;
      lineCSSClass = styles.NavBarLineDark;
      logoLight = false;
      break;
    case SectionBackgroundStyle.dark:
      itemCSSClass = styles.NavBarItemLight;
      lineCSSClass = styles.NavBarLineLight;
      logoLight = true;
      break;
    default:
      break;
    }
    return (
      <div className={styles.NavBarContainer}>
        <Section onlySideMargins style={this.props.style}>
          <div className={this.state.expanded === true ? styles.ContentExpanded : styles.Content}>
            <div className={styles.MobileNavContainer}>
              <Link to="/" className={styles.NavBarLogo}>
                <NavLogo light={logoLight} />
              </Link>
              {
                this.state.expanded === true
                ? <img onClick={() => { this.setState({ expanded: false }) }}
                    className={styles.ExpandCollapseIcon} src={logoLight ? XIconLight : XIconDark} 
                    alt="" />
                : <img onClick={() => { this.setState({ expanded: true }) }}
                    className={styles.ExpandCollapseIcon} src={logoLight ? HamburgerIconLight : HamburgerIconDark} 
                    alt="" />
              }
            </div>
            <div className={this.state.expanded === true 
                ? styles.NavBarItemsContainerExpanded 
                : styles.NavBarItemsContainer
              }>
              <Link to="/mission" className={itemCSSClass}>{missionNavItemHeaderText}</Link>
              <Link to="/method" className={itemCSSClass}>{methodNavItemHeaderText}</Link>
              <Link to="/team" className={itemCSSClass}>{teamNavItemHeaderText}</Link>
              <Link to="/contact" className={itemCSSClass}>{contactNavItemHeaderText}</Link>
            </div>
          </div>
        </Section>
        <div className={lineCSSClass} />
      </div>
    );
  }
    
}
  
  