import React from 'react';
import Page from '../reusable/page_component/Page';
import NavBar from '../nav_bar/NavBar';
import Main from './main/Main';
import Description from './description/Description';
import TextGroupSectionA from './text_group_section_a/TextGroupSectionA';
import TextGroupSectionB from './text_group_section_b/TextGroupSectionB';
import TextGroupSectionC from './text_group_section_c/TextGroupSectionC';
import GetInTouchFooter from '../get_in_touch_footer/GetInTouchFooter';
import Footer from '../footer/Footer';
import { SectionBackgroundStyle } from '../reusable/section/Section';

export default () => (
  <Page>
    <NavBar style={SectionBackgroundStyle.mediumDark} />
    <Main />
    <Description />
    <TextGroupSectionA />
    <TextGroupSectionB />
    <TextGroupSectionC />
    <GetInTouchFooter />
    <Footer />
  </Page>
)
