import React from 'react';
import styles from './TextGroupSectionE.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Amy from '../../assets/amy.png';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

const title = "Amy LeVoir."
const subtitle = "MOST Manager & Administrator, Pediatric Yoga Instructor"
const paragraphs = [
  "Amy joined the MOST team as an administrative assistant in April 2022. She graduated from California State University Fullerton with her Bachelor of Arts in Studio Art. Amy has always had a passion for children and people with disabilities. Prior to joining MOST Kids, Amy worked as a preschool teacher, a caretaker for adults with disabilities, a tutor, a music therapy volunteer, and a summer camp coordinator. Amy is a certified Yoga Instructor (RYT 200) and is currently teaching yoga in schools and homes throughout Los Angeles. In her free time, Amy loves to hike with friends, walk her dog, and make pottery.",
];

export default class TextGroupSectionE extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumDark}>
        <img className={styles.Amy} src={Amy} alt="" />
        <MobileSpacer size={SpacerSize.medium} />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark
            title={title}
            subtitle={subtitle}
            paragraphs={paragraphs} 
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}