import React from 'react';
import styles from './TextGroupSectionA.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Oblong from '../../assets/oblong_i.svg';
import Grid from '../../reusable/grid/Grid';

const title = "We believe in progress."
const text = "Our main goal is to make efficient progress with lasting results. We offer convenient, personalized sessions for your child at home, school, or in our sensory gym."

export default class TextGroupSectionA extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <img className={styles.Oblong} src={Oblong} alt="" />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={true} 
            title={title} 
            paragraphs={[text]}
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}