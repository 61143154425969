import React from 'react';
import styles from './LargeDescription.module.sass';

export type TextComponents = [string, boolean][];

type LargeDescriptionProps = {
  className?: string;
  dark: boolean;
  italicized?: boolean;
  textComponents: TextComponents;
};

export default class LargeDescription extends React.Component<LargeDescriptionProps> {

  render() {
    let normalTextCSSClass = this.props.dark 
      ? (this.props.italicized ? styles.ItalicizedTextDark : styles.TextDark)
      : (this.props.italicized ? styles.ItalicizedTextLight : styles.TextLight)
    let boldTextCSSClass = this.props.dark 
      ? (this.props.italicized ? styles.EmphasizedItalicizedTextDark : styles.EmphasizedTextDark)
      : (this.props.italicized ? styles.EmphasizedItalicizedTextDark : styles.EmphasizedTextLight)
    return (
      <div className={`${this.props.className || ""} ${styles.LargeDescription}`}>
        <div className={normalTextCSSClass}>
          {
            this.props.textComponents.map(function(ele) {
              if (ele[1] === true) {
                return (<span className={boldTextCSSClass} >{ele[0]}</span>)
              } else {
                return (ele[0])
              }
            })
          }
        </div>
      </div>
    )
  }

}