import React from 'react';
import styles from './DescriptionB.module.sass';
import LargeDescription, { TextComponents } from '../../reusable/large_description/LargeDescription';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';

export default class DescriptionB extends React.Component {

  render() {
    const textComponents: TextComponents = [
      ["“Children are naturally joyful and full of light, and in my experience, these ", false],
      ["qualities are contagious.", true],
      ["”", false],
    ];
    return (
      <Section style={SectionBackgroundStyle.mediumDark}>
        <div className={styles.DescriptionB}>
          <LargeDescription dark italicized textComponents={textComponents} />
        </div>
      </Section>
    )
  }

}