import React from 'react';
import styles from './Main.module.sass';
import ShapeA from '../../assets/notebook_and_pencils.svg';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TallCurvyUnderline from '../../assets/tall_curvy_underline.svg';

export default class Main extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.dark}>
        <img className={styles.ShapeA} src={ShapeA} alt="" />
        <div className={styles.Item}>Our</div>
        <div className={styles.Item}>mission.</div>
        <img className={styles.TallCurvyUnderline} src={TallCurvyUnderline} alt="" />
      </Section>
    )
  }

}
