import React from 'react';
import Page from '../reusable/page_component/Page';
import NavBar from '../nav_bar/NavBar';
import Main from './main/Main';
import TextGroupSectionA from './text_group_section_a/TextGroupSectionA';
import Footer from '../footer/Footer';
import { SectionBackgroundStyle } from '../reusable/section/Section';

export default () => (
  <Page>
    <NavBar style={SectionBackgroundStyle.dark} />
    <Main />
    <TextGroupSectionA />
    <Footer />
  </Page>
)
