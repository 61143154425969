import React from 'react';
import styles from './Page.module.sass';

export default class Page extends React.Component {

  render() {
    return (
      <div className={styles.Page}>
        {this.props.children}
      </div>
    )
  }

}