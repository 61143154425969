import React from 'react';
import styles from './TextGroupSectionB.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Surfing from '../../assets/surfing.png';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

const title = "It takes a village."
const text = "Working creatively in collaboration with the child and their parents, caregivers, teachers, doctors, and other health and education professionals, we pride ourselves on helping our clients feel as successful as possible, as quickly as possible."

export default class TextGroupSectionB extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <img className={styles.Surfing} src={Surfing} alt="" />
        <MobileSpacer size={SpacerSize.large} />
        <Grid>
          <TextContainer 
            className={styles.TextContainer}
            dark={true} 
            title={title} 
            paragraphs={[text]} 
            style={TextGroupStyle.small} >
            {this.props.children}
          </TextContainer>
        </Grid>
      </Section>
    )
  }

}