import React from 'react';
import styles from './Main.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import Oblong from '../../assets/oblong_d.svg';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';

export default class Main extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.light}>
        <img className={styles.Oblong} src={Oblong} alt="" />
        <MobileSpacer size={SpacerSize.small} />
        <div className={styles.Container}>
          <div className={styles.Item}>Meet the</div>
          <div className={styles.Item}>most team.</div>
        </div>
      </Section>
    )
  }

}