import React from 'react';
import TitleDescriptionGroup, { TextGroupStyle, Alignment } from '../title_description_group/TitleDescriptionGroup';

type TitleDescriptionGroupContainerProps = {
  className?: string;
  dark: boolean;
  image?: any;
  title?: string;
  subtitle?: string;
  paragraphs?: Array<string>;
  compactHeader?: boolean;
  style?: TextGroupStyle;
  alignment?: Alignment;
};

export default class TitleDescriptionGroupContainer extends React.Component<TitleDescriptionGroupContainerProps> {

  render() {
    return (
      <TitleDescriptionGroup {...this.props} />
    )
  }

}