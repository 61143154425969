import React from 'react';
import styles from './Link.module.sass';

enum LinkSize {
  small,
  medium,
  large
}

type LinkProps = {
  className?: string,
  title: string,
  href: string
  size?: LinkSize,
  image?: any,
  dark?: boolean,
  doNotOpenInNewTab?: boolean
}

export {
  LinkSize
}

export default class Link extends React.Component<LinkProps> {

  render() {
    let cssClass = styles.LinkMedium
    switch (this.props.size) {
    case LinkSize.small:
      cssClass = styles.LinkSmall
      break
    case LinkSize.medium:
      cssClass = styles.LinkMedium
      break
    case LinkSize.large:
      cssClass = styles.LinkLarge
      break
    }
    return (
      <div className={`${this.props.className || ""} ${cssClass}`}>
        {this.props.image && <img className={styles.Image} src={this.props.image} alt="" />}
        <a className={this.props.dark ? styles.AnchorDark : styles.AnchorLight} 
          href={this.props.href}
          rel="noopener noreferrer"
          target={this.props.doNotOpenInNewTab ? "" : "_blank"}>
          {this.props.title}
        </a>
      </div>
    )
  }

}