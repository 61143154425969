import React from 'react';
import Section, { SectionBackgroundStyle } from '../reusable/section/Section';
import Button from '../reusable/button/Button';
import styles from './GetInTouchFooter.module.sass';
import Envelope from '../assets/envelope.svg';
import { Link } from 'react-router-dom';

const title = "Questions?"
const subtitle = "We'd love to hear from you."

export default class GetInTouchFooter extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumDark}>
        <div className={styles.OuterContainer}>
          <img className={styles.Envelope} src={Envelope} alt="" />
          <div className={styles.Container}>
            <div className={styles.Title}>{title}</div>
            <div className={styles.SubTitle}>{subtitle}</div>
            <Link className={styles.Link} to="/contact">
              <Button dark title="CONTACT INFO" />
            </Link>
          </div>
        </div>
      </Section>
    )
  }

}