import React from 'react';
import styles from './IconTextGroup.module.sass';

type IconTextGroupProps = {
  className?: string;
  header: string;
  description: string;
};

export default class IconTextGroup extends React.Component<IconTextGroupProps> {

  render() {
    const Icon = () => <div>{this.props.children}</div>
    return (
      <div className={`${this.props.className || ""} ${styles.IconTextGroup}`}>
        <Icon />
        <div className={styles.Header}>{this.props.header}</div>
        <div className={styles.Description}>{this.props.description}</div>
      </div>
    )
  }

}