import React from 'react';
import styles from './Main.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { Alignment, TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Link from '../../reusable/link/Link';
import EnvelopeLight from '../../assets/envelope_light.svg';
import PhoneIcon from '../../assets/phone_icon.svg';
import Oblong from '../../assets/oblong_g.svg';

const title = "Let’s get in touch."
const email = "admin@mostkids.org"
const phoneNumber = "(310) 437-0202"

export default class Main extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.dark}>
        <img className={styles.Oblong} src={Oblong} alt="" />
        <TextContainer alignment={Alignment.right} style={TextGroupStyle.large} dark={false} title={title} >
          <Link className={styles.FirstLink} doNotOpenInNewTab image={EnvelopeLight} title={email} href={`mailto:${email}`} />
          <Link doNotOpenInNewTab image={PhoneIcon} title={phoneNumber} href={`tel:1 ${phoneNumber}`} />
        </TextContainer>
      </Section>
    )
  }

}