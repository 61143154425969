import React from 'react'
import styles from './Button.module.sass'

type ButtonProps = {
  dark: boolean;
  title: string;
};

export default class Button extends React.Component<ButtonProps> {

  render() {
    return (
      <button 
        className={this.props.dark ? styles.ButtonDark : styles.Button}>
        {this.props.title}
      </button>
    )
  }

}