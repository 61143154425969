import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.sass';
import MostSimpleLogo from "../assets/most_simple.svg";
import MostIcon from "../assets/most_icon.svg";
import InstagramIcon from "../assets/instagram_icon.svg";
import Section, { SectionBackgroundStyle } from "../reusable/section/Section";

function DividerLine() {
  return (
    <div className={styles.DividerLine}>|</div>
  )
}

export default class Footer extends React.Component {

  render() {
    return (
      <Section onlySideMargins style={SectionBackgroundStyle.veryDark}>
        <div className={styles.Footer}>
          <div className={styles.Container}>
            <Link to="/" className={styles.MostIconsContainer}>
              <img src={MostSimpleLogo} alt="" />
              <img src={MostIcon} alt="" />
            </Link>

            <div className={styles.ButtonsContainer}>
              <Link to="/mission" className={styles.Button}>MISSION</Link>
              <DividerLine />
              <Link to="/method" className={styles.Button}>METHOD</Link>
              <DividerLine />
              <Link to="/team" className={styles.Button}>TEAM</Link>
              <DividerLine />
              <Link to="/contact" className={styles.Button}>CONTACT</Link>
            </div>
            <a
              className={styles.InstagramIcon}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/mostkidsot/">
              <img src={InstagramIcon} alt="" />
            </a>
          </div>
        </div>
      </Section>
    );
  }

}
