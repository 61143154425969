import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import Home from './home/Home';
import Mission from './mission_page/Mission';
import MethodPage from './method_page/MethodPage';
import TeamPage from './team_page/TeamPage';
import ContactPage from './contact_page/ContactPage';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/mission">
            <Mission />
          </Route>
          <Route exact path="/method">
            <MethodPage />
          </Route>
          <Route exact path="/team">
            <TeamPage />
          </Route>
          <Route exact path="/contact">
            <ContactPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}