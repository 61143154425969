import React from 'react';
import styles from './Description.module.sass';
import LargeDescription, { TextComponents } from '../../reusable/large_description/LargeDescription';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import CurvyUnderline from '../../assets/curvy_underline.svg';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer'

export default class Description extends React.Component {
  render() {
    const textComponents: TextComponents = [
      ["See for yourself why we’re the most fun OTs around: professional, personable, and most importantly — ", false],
      ["playful.", true],
    ]
    return (
      <Section style={SectionBackgroundStyle.light}>
        <div className={styles.Description}>
          <LargeDescription dark={true} textComponents={textComponents} />
        </div>
        <div className={styles.ImageContainer}>
          <img className={styles.CurvyUnderline} src={CurvyUnderline} alt="" />
        </div>
        <MobileSpacer size={SpacerSize.small} />
      </Section>
    )
  }

}