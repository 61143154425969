import React from 'react';
import styles from './Description.module.sass';
import ShapeA from '../../assets/oblong_light_with_pencil.svg';
import LargeDescription from '../../reusable/large_description/LargeDescription';
import { TextComponents } from '../../reusable/large_description/LargeDescription';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import MobileSpace, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

const text = "We are a pediatric occupational therapy practice serving children and families in Los Angeles.";

export default class Description extends React.Component {
  render() {
    const textComponents: TextComponents = [
      [text, false]
    ];
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <MobileSpace size={SpacerSize.small} />
        <img className={styles.ShapeA} src={ShapeA} alt="" />
        <Grid>
          <LargeDescription 
            className={styles.Description}
            dark={true} 
            textComponents={textComponents} />
        </Grid>
      </Section>
    )
  }

}