import React from 'react';
import styles from './TextGroupSectionA.module.sass';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Jen from '../../assets/jen.png';
import Grid from '../../reusable/grid/Grid';

const title = "Jennifer Morgan."
const subtitle = "Founder & Pediatric Occupational Therapist"
const paragraphs = [
  "Jennifer is a pediatric occupational therapist with more than a decade of experience practicing occupational therapy, and many more years of experience working with children in a variety of settings. A native of Los Angeles, Jennifer received her Master’s Degree in Occupational Therapy from the University of Southern California, where she received a federally funded grant to train as a school based and early childhood practitioner.",
  "Jennifer founded MOST in Malibu in 2013, after spending years working as the lead occupational therapist for all Malibu public schools, and feeling dissatisfied with the level of flexibility and creativity the school district allowed. At  MOST, Jennifer has been able to utilize her innate talent, specialized training, and experience to help each child succeed. In 2015, Jennifer expanded her services by instituting the occupational therapy program at Park Century School, an independent school dedicated to providing individualized education to students with learning differences.",
]

export default class TextGroupSectionA extends React.Component {

  render() {
    return (
      <div>
        <MobileSpacer size={SpacerSize.medium} />
        <img className={styles.Jen} src={Jen} alt="" />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={true}
            title={title}
            subtitle={subtitle}
            paragraphs={paragraphs}
            style={TextGroupStyle.small} />
        </Grid>
      </div>
    )
  }

}
