import React from 'react';
import Page from '../reusable/page_component/Page';
import NavBar from '../nav_bar/NavBar';
import Main from './main/Main';
import Description from './description/Description';
import TextGroupSectionA from './text_group_section_a/TextGroupSectionA';
import TextGroupSectionB from './text_group_section_b/TextGroupSectionB';
import DescriptionB from './description_b/DescriptionB';
import TextGroupSectionC from './text_group_section_c/TextGroupSectionC';
import TextGroupSectionD from './text_group_section_d/TextGroupSectionD';
import TextGroupSectionE from './text_group_section_e/TextGroupSectionE';
import TextGroupSectionF from './text_group_section_f/TextGroupSectionF';
import Footer from '../footer/Footer';
import GetInTouchFooter from '../get_in_touch_footer/GetInTouchFooter';
import Section, { SectionBackgroundStyle } from '../reusable/section/Section';

export default () => (
  <Page>
    <NavBar style={SectionBackgroundStyle.light} />
    <Main />
    <Description />
    <Section style={SectionBackgroundStyle.mediumLight}>
      <TextGroupSectionA />
      <TextGroupSectionB />
    </Section>
    <DescriptionB />
    <TextGroupSectionC />
    <TextGroupSectionF />
    <TextGroupSectionE />
    <TextGroupSectionD />
    <GetInTouchFooter />
    <Footer />
  </Page>
)
