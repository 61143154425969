import React from 'react';
import styles from './Main.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import Oblong from '../../assets/oblong_b.svg'
import Paintbrush from '../../assets/paintbrush.svg'

export default class Main extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumDark}>
        <div className={styles.Container}>
          <img className={styles.Oblong} src={Oblong} alt="" />
          <img className={styles.Paintbrush} src={Paintbrush} alt="" />
          <div className={styles.Item}>Our</div>
          <div className={styles.Item}>method.</div>
        </div>
      </Section>
    )
  }

}
