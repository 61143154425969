import React from 'react';
import Button from '../../reusable/button/Button';
import styles from './SectionD.module.sass';
import Fun from '../../assets/fun.png';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import { Link } from 'react-router-dom';
import Grid from '../../reusable/grid/Grid';

const title = "Our team.";
const paragraphs = [
  "When you do what you love, it doesn’t feel like work - especially when you get to let your inner child out.",
];

export default class SectionD extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.light}>
        <Grid>
          <img className={styles.Fun} src={Fun} alt="" />
          <TextContainer
            className={styles.Container}
            title={title}
            paragraphs={paragraphs}
            dark
            style={TextGroupStyle.small}>
            <div className={styles.Button}>
              <Link to="/team">
                <Button dark={true} title={"MEET US"} />
              </Link>
            </div>
          </TextContainer>
          <MobileSpacer size={SpacerSize.large} />
        </Grid>
      </Section>
    );
  }

}
