import React from 'react';
import styles from './TextGroupSectionB.module.sass';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Oblong from '../../assets/oblong_e.svg';
import OblongB from '../../assets/oblong_f.svg';
import PalmTree from '../../assets/palm_tree.svg';
import Grid from '../../reusable/grid/Grid';

const paragraphs = [
  "Jennifer’s areas of specialty include Early Intervention, School Based Therapy, Sensory Processing Integration, Reflex Integration, Handwriting Without Tears, ALERT Program, and Interactive Metronome. In addition, she incorporates alternative wholistic and intuitive approaches to healing into her practice. Parents, teachers, professionals, and especially the children she works with, enjoy having Jennifer as their OT; they appreciate her expertise, and her efficient and direct approach to helping them grow to their fullest potential. Jennifer’s practice allows her the freedom to work on a wide range of skills with clients, and she tailors a therapeutic program for each child.",
  "She is passionate about her work, and this is reflected in her ever expanding involvement in the community.  Jennifer is a Board Member for the Malibu Chamber of Commerce and the Boys & Girls Club of Malibu Wellness Advisory Council, and she co-organizes events with THERAsurf, bringing her expertise and exuberance to every interaction she encounters.",
]

export default class TextGroupSectionB extends React.Component {

  render() {
    return (
      <div className={styles.Container}>
        <img className={styles.Oblong} src={Oblong} alt="" />
        <img className={styles.OblongB} src={OblongB} alt="" />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={true}
            paragraphs={paragraphs}
            style={TextGroupStyle.small} />
        </Grid>
        <div className={styles.PalmTreeContainer}>
          <img className={styles.PalmTree} src={PalmTree} alt="" />
        </div>
      </div>
    )
  }

}
