import React from 'react';
import Button from '../../reusable/button/Button';
import styles from './SectionE.module.sass';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import Oblong from '../../assets/oblong_c.svg';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Phone from '../../assets/corded_telephone.svg';
import { Link } from 'react-router-dom';
import Grid from '../../reusable/grid/Grid';

const title = "Get in touch."
const paragraphs = [
  "Drop us a line. We’d love to hear from you.",
];

export default class SectionE extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.dark}>
        <MobileSpacer size={SpacerSize.medium} />
        <img className={styles.Phone} src={Phone} alt="" />
        <img className={styles.Oblong} src={Oblong} alt="" />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            title={title}
            paragraphs={paragraphs}
            dark={false}
            style={TextGroupStyle.small}>
            <div className={styles.Button}>
              <Link to="/contact">
                <Button dark={false} title={"CONTACT"} />
              </Link>
            </div>
          </TextContainer>
        </Grid>
        <MobileSpacer size={SpacerSize.medium} />
      </Section>
    );
  }

}