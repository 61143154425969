import React from 'react';
import NavBar from '../nav_bar/NavBar';
import Main from './main/Main';
import Description from './description/Description';
import SectionB from './section_b/SectionB';
import SectionC from './section_c/SectionC';
import SectionD from './section_d/SectionD';
import SectionE from './section_e/SectionE';
import Footer from '../footer/Footer';
import Page from '../reusable/page_component/Page'
import { SectionBackgroundStyle } from '../reusable/section/Section';

export default () => (
  <Page>
    <NavBar style={SectionBackgroundStyle.mediumLight} />
    <Main />
    <Description />
    <SectionB />
    <SectionC />
    <SectionD />
    <SectionE />
    <Footer />
  </Page>
)