import React from 'react';
import styles from './Description.module.sass';
import LargeDescription, { TextComponents } from '../../reusable/large_description/LargeDescription';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';

export default class Description extends React.Component {

  render() {
    const textComponents: TextComponents = [
      ["Jennifer Morgan and her team provide high-quality and individualized services, using a ", false],
      ["wholistic", true],
      [" and ", false],
      ["child-centered", true],
      [" approach.", false],
    ]
    return (
      <Section style={SectionBackgroundStyle.mediumDark}>
        <div className={styles.Description}>
          <LargeDescription dark={true} textComponents={textComponents} />
        </div>
      </Section>
    )
  }

}
