import React from 'react';
import styles from './Description.module.sass';
import LargeDescription, { TextComponents } from '../../reusable/large_description/LargeDescription';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import Oblong from '../../assets/oblong_h.svg';

export default class Description extends React.Component {

  render() {
    const textComponents: TextComponents = [
      ["Our mission is to provide each client with the ", false],
      ["skills and tools", true],
      [" they need to achieve their greatest potential, and to spread ", false],
      ["awareness", true],
      [" of occupational therapy so that more children and families can get the ", false],
      ["most out of life.", true],
    ];
    return (
      <Section style={SectionBackgroundStyle.dark}>
        <img className={styles.Oblong} src={Oblong} alt="" />
        <div className={styles.Description}>
          <LargeDescription dark={false} textComponents={textComponents} />
        </div>
      </Section>
    )
  }

}