import React from 'react';
import styles from './TextGroupSectionC.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Helping from '../../assets/helping.svg';
import Grid from '../../reusable/grid/Grid';

const title = "What is occupational therapy?";
const text = "Occupational therapy (OT)  is a unique profession that helps individuals become more successful and independent in their main “occupations”, or essential daily tasks.  Childrens’ primary occupations include playing, learning, socializing, eating, and mastering milestones and their environment. In OT, we help children achieve their greatest potential by addressing underlying developmental skills to help them function to the best of their abilities throughout their lives."

export default class TextGroupSectionC extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.light}>
        <img className={styles.Helping} src={Helping} alt="" />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={true} 
            title={title} 
            paragraphs={[text]}
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}