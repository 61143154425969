import React from 'react';
import styles from './TextGroupSectionD.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Cali from '../../assets/cali.png';
import Curve from '../../assets/rapid_curve.svg';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import Grid from '../../reusable/grid/Grid';

const title = "Cali.";
const subtitle = "Happiness Officer";
const paragraphs = [
  "Cali is a Havanese puppy who joined the team in January 2018. She has been working hard on her training to become a therapy dog for all of the kids who come to play and work on their skills at MOST ! Cali loves playing fetch, enjoys long walks near the beach in Malibu and Pacific Palisades, spending time with pals, and taking naps around the office.",
  "Cali looks forward to her future work in OT sessions and is always very excited to meet new people and make new friends. We are so happy that she has joined our amazing team!",
];

export default class TextGroupSectionD extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <img className={styles.Cali} src={Cali} alt="" />
        <MobileSpacer size={SpacerSize.medium} />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark 
            title={title}
            subtitle={subtitle}
            paragraphs={paragraphs}
            style={TextGroupStyle.small} />
        </Grid>
        <img className={styles.Curve} src={Curve} alt="" />
      </Section>
    )
  }

}