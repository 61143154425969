import React from 'react';
import styles from './TextGroupSectionA.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import Link from '../../reusable/link/Link';
import Logo from '../../assets/alternate_logo.svg';
import MobileSpace, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';

export default class TextGroupSectionA extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <img className={styles.Logo} src={Logo} alt="" />
        <TextContainer dark={true} title={"We love to socialize."} paragraphs={[]}>
          <Link className={styles.FirstLink} dark title={"instagram"} href="https://www.instagram.com/mostkidsot/" />
          <Link dark title={"facebook"} href="https://www.facebook.com/mostkidsOT" />
        </TextContainer>
        <MobileSpace size={SpacerSize.large} />
      </Section>
    )
  }

}
