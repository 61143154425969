import React from 'react';
import IconTextGroup from '../../reusable/icon_text_group/IconTextGroup';
import Button from '../../reusable/button/Button';
import styles from './SectionC.module.sass';
import HouseSVG from '../../assets/house.svg';
import NeedleSVG from '../../assets/needle.svg';
import PocketWatchSVG from '../../assets/pocket_watch.svg';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import Oblong from '../../assets/oblong_j.svg';
import OblongB from '../../assets/oblong_k.svg';
import { Link } from 'react-router-dom';

const headerA = "Tailored"
const descriptionA = "We believe every child is unique and our services reflect this. We offer customized evaluations and sessions to fit your needs."

const headerB = "Private"
const descriptionB = "We all have places to go and people to see. That’s why we make it easy for you to fit OT sessions into you and your child’s schedule."

const headerC = "Efficient"
const descriptionC = "Our goal is for your child not to need us anymore. Maybe it takes one session, one month, or one year. No matter what, we make it fun."

export default class SectionC extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.mediumLight}>
        <img className={styles.Oblong} src={Oblong} alt="" />
        <img className={styles.OblongB} src={OblongB} alt="" />
        <div className={styles.Header}>The most advantage.</div>
        <div className={styles.Container}>
          <IconTextGroup className={styles.Item} header={headerA} description={descriptionA}>
            <div className={styles.IconContainer}>
              <img className={styles.Icon} src={NeedleSVG} alt="" />
            </div>
          </IconTextGroup>
          <IconTextGroup className={styles.Item} header={headerB} description={descriptionB}>
            <div className={styles.IconContainer}>
              <img className={styles.Icon} src={HouseSVG} alt="" />
            </div>
          </IconTextGroup>
          <IconTextGroup className={styles.Item} header={headerC} description={descriptionC}>
            <div className={styles.IconContainer}>
              <img className={styles.Icon} src={PocketWatchSVG} alt="" />
            </div>
          </IconTextGroup>
        </div>
        <div className={styles.Button}>
          <Link to="/method">
            <Button title={"OUR METHOD"} dark={true} />
          </Link>
        </div>
      </Section>
    )
  }

}