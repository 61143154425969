import React from 'react';
import styles from './TextGroupSectionB.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import Oblong from '../../assets/oblong_c.svg';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer'
import Circle2 from '../../assets/circle_2.svg'
import Grid from '../../reusable/grid/Grid';

const title = "Private sessions."
const text = "We work with you and your schedule to offer sessions in the convenience of your home, local private schools, or in our sensory gym."

export default class TextGroupSectionB extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.light}>
        <img className={styles.Oblong} src={Oblong} alt="" />
        <MobileSpacer size={SpacerSize.medium} />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={true}
            image={Circle2}
            title={title}
            paragraphs={[text]}
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}
