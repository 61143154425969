import React from 'react';
import styles from './TextGroupSectionC.module.sass';
import Section, { SectionBackgroundStyle } from '../../reusable/section/Section';
import TextContainer from '../../reusable/title_description_group_container/TitleDescriptionGroupContainer';
import { TextGroupStyle } from '../../reusable/title_description_group/TitleDescriptionGroup';
import MobileSpacer, { SpacerSize } from '../../reusable/mobile_spacer/MobileSpacer';
import BoyWithBackpack from '../../assets/boy_with_backpack.png';
import Circle3 from '../../assets/circle_3.svg';
import Grid from '../../reusable/grid/Grid';

const title = "Efficient results."
const text = "Our goal is to help your child achieve their goals quickly and efficiently. We want them to be independent and successful as soon as possible."

export default class TextGroupSectionC extends React.Component {

  render() {
    return (
      <Section style={SectionBackgroundStyle.dark}>
        <MobileSpacer size={SpacerSize.large} />
        <img className={styles.BoyWithBackpack} src={BoyWithBackpack} alt="" />
        <Grid>
          <TextContainer
            className={styles.TextContainer}
            dark={false}
            image={Circle3}
            title={title}
            paragraphs={[text]}
            style={TextGroupStyle.small} />
        </Grid>
      </Section>
    )
  }

}
